(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:neoInput
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="components">
   <file name="index.html">
   <neo-input></neo-input>
   </file>
   </example>
   *
   */
  angular
    .module('neo')
    .directive('neoInput', neoInput);

  function neoInput() {
    return {
      restrict: 'EA',
      scope: {
        label: '@',
        icon: '@',
        neoIcon: '@',
        error: '@',
        pattern: '@',
        noMargin: '=',
        isRequired: '=',
        isDisabled: '=',
        isReadonly: '=',
        ngModel: '='
      },
      require: '?^form',
      templateUrl: 'directives/neo-input/neo-input-directive.tpl.html',
      replace: false,
      controllerAs: 'vm',
      controller: function ($scope) {
        var vm = this;
        vm.name = 'neoInput';
        vm.pattern = new RegExp($scope.pattern);
      },
      link: function (scope, element, attrs, formCtrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        scope.name = attrs.name;
        scope.form = formCtrl;
      }
    };
  }
}());
